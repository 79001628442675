<template>
  <v-dialog 
		:value="checkTaxReminderAt"
		persistent 
		max-width="700"
		>
    <v-card >
      <v-card-title primary-title class="title"> 
				Você conseguiu pagar seus impostos dentro do prazo este mês? 
			</v-card-title>
			<v-card-text class="subheading" > 
				Caso tenha conseguido, pedimos para que sinalize as guias como "pagas", somente assim você tem o total controle dos seus pagamentos.
				<br/>
				Se acabou perdendo o prazo, não tem problema, basta solicitar a reemissão de guias em nossa área de serviços adicionais
			</v-card-text>	
			<v-card-actions>

				<v-row   justify="center">
					<v-btn 
						color="success"
					 @click="accept()"
					>
						estou ciente
					</v-btn>
				</v-row>
			</v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
     	showDialog: false,
    };
  },
  methods: {
		accept(){
			let data = {'taxReminderAt':this.$moment().format('YYYY-MM-DD')}
			this.$store.dispatch('company/updateCompany',data)
			this.$store.commit('taxations/setCheckTaxReminderAt', false)
		}
  },
  computed: {
		...mapGetters({
			company: 'company/company',
			taxes: 'taxations/taxes',
			checkTaxReminderAt: 'taxations/checkTaxReminderAt',
		}),
		filterTexes(){
			return this.taxes.filter(tax => {
				let checkCompetence =  this.$moment(tax.canvas,"YYYY-MM-DD").add(1,'M').format('MM/Y') === this.$moment().format('MM/Y') 
				let checkStatus =  tax.idStatus === 1
				return !!checkCompetence && !!checkStatus
			})
		},
		checkTaxOpened(){
			if( this.filterTexes.length > 0) return true
			return false
		},
		maxCurrentDueAt(){
			if(this.filterTexes.length > 0){
				return this.filterTexes.reduce((a, b) => { 
					return a.currentDueAt > b.currentDueAt ? a.currentDueAt : b.currentDueAt 
				},{currentDueAt:null});
			}
		},
		taxReminderAt(){
			if(!!this.company.taxReminderAt) {
				return this.$moment(this.company.taxReminderAt,"YYYY-MM-DD").format('MM/Y') === this.$moment().format('MM/Y')
			}else{
				return false
			}
		},
	
	},
	mounted() {
		this.$store.dispatch('company/getCompany');
		this.$store.dispatch('taxations/getAll')
		.then(() => {
				if(this.$moment({  minute :0, second :0, millisecond :0}).isAfter(this.$moment(this.maxCurrentDueAt,"YYYY-MM-DD"))){
				if(localStorage.getItem('checkTaxReminderAt') != 'false'){
					this.$store.commit('taxations/setCheckTaxReminderAt', this.checkTaxOpened)
				}
				if(!this.taxReminderAt){
					this.$store.commit('taxations/setCheckTaxReminderAt', this.checkTaxOpened)
				}
			}
		})		
	},
};
</script>

<style scoped>
</style>